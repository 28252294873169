<template>
  <div class="wrap-layout">
    <v-row class="mb-4">
      <v-col class="header" cols="12">
        <span class="heading-2">Notifications</span>
        <v-btn v-if="notificationsCountUnread" class="btn-blue" @click="markAll">
          <v-icon dark left> mdi-check-all</v-icon>
          Mark all as read
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" class="tabs">
          <v-tab>All</v-tab>
          <v-tab :disabled="!notificationsCountUnread">Unread</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tab-items">
          <v-tab-item>
            <v-card class="tab-content-wrap" color="white" flat>
              <v-skeleton-loader
                v-if="isLoading"
                type=" list-item-three-line, list-item-three-line, list-item-three-line"
              ></v-skeleton-loader>
              <template v-else>
                <NotificationItem v-for="item in renderItems" :key="item.id" :item="item" />
              </template>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="tab-content-wrap" flat>
              <NotificationItem v-for="item in renderItems" :key="item.id" :item="item" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12">
        <Pagination
          :page="page"
          :perPage="perPage"
          :total="itemsLength"
          @onPageChange="onPageChange"
          @onPerPageChange="onItemsPerPageChange"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions as mapPiniaActions, mapState as mapPiniaGetters } from "pinia";

import NotificationItem from "@/components/Notifications/NotificationItem.vue";
import Pagination from "@/components/Pagination/View.vue";
import { useAppStore } from "@/pinia-store/app";
import { useNotificationsStore } from "@/pinia-store/notifications";
import { paginate } from "@/pinia-store/slots";
import { NotificationTypes } from "@/types/notificationTypes";

export default {
  name: "Notifications",
  components: { Pagination, NotificationItem },
  data: () => ({
    tab: 0,
    perPage: 10,
    page: 1,
  }),
  computed: {
    ...mapPiniaGetters(useNotificationsStore, ["notifications", "isLoading"]),

    renderItems() {
      return paginate(
        this.tab === 0 ? this.notifications : this.notifications.filter(($notification) => !$notification.seenContent),
        this.perPage,
        this.page,
      );
    },
    itemsLength() {
      return this.tab === 0
        ? this.notifications.length
        : this.notifications.filter(($notification) => !$notification.seenContent).length;
    },
    notificationsCountUnread() {
      return this.notifications.filter(($notification) => !$notification.seenContent).length;
    },
  },
  watch: {
    tab() {
      this.page = 1;
    },
  },

  methods: {
    ...mapPiniaActions(useNotificationsStore, ["getNotifications", "markSeen"]),
    ...mapPiniaActions(useAppStore, ["setLayoutClass"]),
    onPageChange(page) {
      this.page = page;
    },
    async markAll(e) {
      e.stopPropagation();
      await this.markSeen({ seenContent: true, kind: Object.keys(NotificationTypes) });
    },
    onItemsPerPageChange(perPage) {
      this.perPage = perPage;
      if (this.page > this.itemsLength) {
        this.page = this.itemsLength;
      }
    },
  },
  beforeDestroy() {
    this.setLayoutClass("");
  },
  async mounted() {
    this.setLayoutClass("gray");
    await this.getNotifications();
  },
};
</script>

<style lang="scss" scoped>
$gray: #fafafd;
.wrap-layout {
  padding: 30px 30px 80px;
  background: $gray;
  //min-height: calc(100vh - #{$header-height});
  .pagination-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .tab-content-wrap {
    padding: 15px 20px;

    > div {
      border-top: 1px solid #e7e8f2;

      &:first-child {
        border-color: transparent;
      }

      &:first-child:hover + div {
        border-top-color: transparent;
      }

      &:hover + div {
        border-top-color: transparent;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tabs {
    background: $gray;

    :deep .v-tabs-bar {
      background: $gray;
    }
  }

  .tab-items {
    margin-top: 27px;
  }

  :deep .v-tabs-items {
    background: $gray;
  }
}
</style>
